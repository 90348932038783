import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { string, arrayOf } from 'prop-types';
import favicon16 from '../images/favicon/scanship-favicon-16.png';
import favicon32 from '../images/favicon/scanship-favicon-32.png';
import favicon64 from '../images/favicon/scanship-favicon-64.png';

const SEO = ({
  title,
  author,
  description,
  image,
  keywords,
  lang,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query default {
        site {
          siteMetadata {
            title
            author
            description
            image
          }
        }
      }
    `
  );
  const siteDescription =
    description || site.siteMetadata.description;
  const siteTitle = title || site.siteMetadata.title;
  const siteAuthor = author || site.siteMetadata.author;
  const siteImage = image || site.siteMetadata.image;
  const siteKeywords = keywords || ['Scanship'];

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      meta={[
        {
          name: 'description',
          content: siteDescription,
        },
        {
          property: `og:title`,
          content: siteTitle,
        },
        {
          property: `og:description`,
          content: siteDescription,
        },
        {
          property: `og:image`,
          content: siteImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteAuthor,
        },
        {
          name: `twitter:title`,
          content: siteTitle,
        },
        {
          name: `twitter:description`,
          content: siteDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat({
        name: `keywords`,
        content: siteKeywords.join(','),
      })}
      link={[
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: `${favicon16}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: `${favicon32}`,
        },
        {
          rel: 'shortcut icon',
          type: 'image/png',
          href: `${favicon64}`,
        },
      ]}
    />
  );
};

SEO.propTypes = {
  title: string.isRequired,
  author: string,
  description: string,
  image: string,
  lang: string,
  // keywords: arrayOf(string).isRequired,
};
SEO.defaultProps = {
  author: '',
  description: '',
  image: '',
  lang: 'sv',
};

export default SEO;
